@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@import url("icons.generated.css");

	:root {
		/* Project design tokens */

		/* Colors */

		--brand-50: 210 75% 97%;
		--brand-100: 210 69% 94%;
		--brand-200: 210 70% 87%;
		--brand-300: 209 70% 73%;
		--brand-400: 209 70% 51%;
		--brand-500: 209 100% 39%;
		--brand-600: 208 100% 30%;
		--brand-700: 209 100% 24%;
		--brand-800: 210 100% 17%;
		--brand-900: 210 100% 12%;
		--brand-950: 254 55% 24%;

		--gray-25: 240 20% 99%;
		--gray-50: 210 20% 98%;
		--gray-100: 216 24% 96%;
		--gray-200: 220 17% 93%;
		--gray-300: 217 16% 84%;
		--gray-400: 218 15% 65%;
		--gray-500: 221 13% 46%;
		--gray-600: 216 18% 34%;
		--gray-700: 217 24% 27%;
		--gray-800: 216 32% 14%;
		--gray-900: 220 43% 11%;

		--gray-blue-50: 231 36% 98%;
		--gray-blue-100: 231 36% 94%;
		--gray-blue-200: 231 36% 88%;
		--gray-blue-300: 231 36% 78%;
		--gray-blue-400: 232 36% 59%;
		--gray-blue-500: 232 36% 48%;
		--gray-blue-600: 232 36% 38%;
		--gray-blue-700: 231 36% 33%;
		--gray-blue-800: 230 36% 25%;
		--gray-blue-900: 231 36% 10%;
		--gray-blue-950: 231 36% 10%;

		--blue-light-50: 204 100% 97%;
		--blue-light-100: 204 96% 94%;
		--blue-light-200: 201 96% 86%;
		--blue-light-300: 199 97% 74%;
		--blue-light-400: 198 95% 60%;
		--blue-light-500: 199 91% 48%;
		--blue-light-600: 200 100% 39%;
		--blue-light-700: 201 98% 32%;
		--blue-light-800: 201 92% 27%;
		--blue-light-900: 202 82% 24%;
		--blue-light-950: 202 82% 14%;

		--blue-50: 206 100% 97%;
		--blue-100: 209 100% 91%;
		--blue-200: 206 100% 85%;
		--blue-300: 206 100% 76%;
		--blue-400: 207 98% 66%;
		--blue-500: 211 95% 58%;
		--blue-600: 215 87% 51%;
		--blue-700: 218 80% 46%;
		--blue-800: 220 75% 38%;
		--blue-900: 218 68% 31%;
		--blue-950: 218 68% 20%;

		--blue-dark-50: 220 100% 97%;
		--blue-dark-100: 220 100% 91%;
		--blue-dark-200: 220 100% 85%;
		--blue-dark-300: 220 100% 76%;
		--blue-dark-400: 220 100% 66%;
		--blue-dark-500: 220 100% 58%;
		--blue-dark-600: 220 87% 51%;
		--blue-dark-700: 220 100% 46%;
		--blue-dark-800: 220 100% 38%;
		--blue-dark-900: 220 100% 31%;
		--blue-dark-950: 220 100% 20%;

		--indigo-50: 220 100% 97%;
		--indigo-100: 220 100% 94%;
		--indigo-200: 222 98% 89%;
		--indigo-300: 224 96% 82%;
		--indigo-400: 228 91% 74%;
		--indigo-500: 233 86% 67%;
		--indigo-600: 237 77% 59%;
		--indigo-700: 239 60% 51%;
		--indigo-800: 238 57% 41%;
		--indigo-900: 236 49% 34%;
		--indigo-950: 236 49% 24%;

		--purple-50: 244 100% 98%;
		--purple-100: 245 93% 95%;
		--purple-200: 245 97% 92%;
		--purple-300: 247 97% 85%;
		--purple-400: 249 94% 76%;
		--purple-500: 252 92% 66%;
		--purple-600: 256 85% 58%;
		--purple-700: 257 72% 50%;
		--purple-800: 257 71% 42%;
		--purple-900: 257 69% 35%;
		--purple-950: 257 69% 22%;

		--fuchsia-50: 291 98% 98%;
		--fuchsia-100: 289 98% 95%;
		--fuchsia-200: 290 94% 91%;
		--fuchsia-300: 289 95% 83%;
		--fuchsia-400: 290 93% 73%;
		--fuchsia-500: 290 86% 61%;
		--fuchsia-600: 291 71% 49%;
		--fuchsia-700: 293 74% 40%;
		--fuchsia-800: 293 72% 33%;
		--fuchsia-900: 295 66% 28%;
		--fuchsia-950: 295 65% 18%;

		--pink-50: 319 75% 97%;
		--pink-100: 318 80% 95%;
		--pink-200: 318 87% 90%;
		--pink-300: 319 89% 82%;
		--pink-400: 321 88% 70%;
		--pink-500: 318 83% 60%;
		--pink-600: 325 73% 51%;
		--pink-700: 327 80% 42%;
		--pink-800: 328 76% 35%;
		--pink-900: 328 71% 30%;
		--pink-950: 328 71% 18%;

		--orange-50: 29 90% 96%;
		--orange-100: 30 90% 92%;
		--orange-200: 36 86% 83%;
		--orange-300: 27 89% 72%;
		--orange-400: 23 88% 61%;
		--orange-500: 21 87% 53%;
		--orange-600: 17 82% 48%;
		--orange-700: 13 80% 40%;
		--orange-800: 11 71% 34%;
		--orange-900: 11 67% 28%;
		--orange-950: 11 67% 19%;

		--orange-dark-50: 53 96% 90%;
		--orange-dark-100: 53 95% 78%;
		--orange-dark-200: 53 100% 49%;
		--orange-dark-300: 53 100% 40%;
		--orange-dark-400: 53 100% 29%;
		--orange-dark-500: 53 100% 23%;
		--orange-dark-600: 53 100% 17%;
		--orange-dark-700: 53 100% 14%;
		--orange-dark-800: 53 100% 14%;
		--orange-dark-900: 53 100% 10%;
		--orange-dark-950: 53 100% 7%;

		--error-50: 355 100% 98%;
		--error-100: 354 91% 95%;
		--error-200: 351 92% 90%;
		--error-300: 351 94% 80%;
		--error-400: 348 97% 55%;
		--error-500: 350 100% 42%;
		--error-600: 350 100% 33%;
		--error-700: 349 100% 27%;
		--error-800: 348 100% 19%;
		--error-900: 345 100% 14%;
		--error-950: 8 75% 19%;

		--warning-50: 25 100% 97%;
		--warning-100: 24 94% 93%;
		--warning-200: 23 94% 86%;
		--warning-300: 23 95% 69%;
		--warning-400: 23 100% 43%;
		--warning-500: 23 100% 35%;
		--warning-600: 23 100% 27%;
		--warning-700: 23 100% 22%;
		--warning-800: 23 100% 15%;
		--warning-900: 24 100% 11%;
		--warning-950: 18 80% 17%;

		--success-50: 84 79% 93%;
		--success-100: 84 77% 85%;
		--success-200: 83 78% 66%;
		--success-300: 83 100% 40%;
		--success-400: 83 100% 28%;
		--success-500: 83 100% 23%;
		--success-600: 83 100% 17%;
		--success-700: 83 100% 14%;
		--success-800: 83 100% 10%;
		--success-900: 83 100% 7%;
		--success-950: 156 83% 11%;

		/* Shadows and Elevation levels */

		--shadow-xs: 0px 1px 2px 0px hsl(var(--gray-900) / 0.05);
		--shadow-sm: 0px 1px 2px 0px hsl(var(--gray-900) / 0.06);

		/* @TODO: Remove if not used in final design, or simplify if possible */

		--shadow-level-0: 0px 0px 0.3px 0px rgba(0, 0, 0, 0.08), 0px 0px 2px 0px rgba(0, 0, 0, 0.16);
		--shadow-level-1: 0px 0.2px 0.3px 0px rgba(0, 0, 0, 0.08), 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
		--shadow-level-2:
			0px 0.3px 0.7px 0px rgba(0, 0, 0, 0.06),
			0px 1.5px 2.5px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
		--shadow-level-3:
			0px 0.5px 1.5px 0px rgba(0, 0, 0, 0.05), 0px 3.5px 4px 0px rgba(0, 0, 0, 0.07),
			0px 8px 12px 0px rgba(0, 0, 0, 0.09), 0px 16px 24px 0px rgba(0, 0, 0, 0.14);
		--shadow-level-4:
			0px 1px 2.5px 0px rgba(0, 0, 0, 0.04), 0px 4px 5px 0px rgba(0, 0, 0, 0.06),
			0px 10px 12px 0px rgba(0, 0, 0, 0.07), 0px 20px 25px 0px rgba(0, 0, 0, 0.09),
			0px 32px 48px 0px rgba(0, 0, 0, 0.13);
		--shadow-level-5:
			0px 1.5px 3px 0px rgba(0, 0, 0, 0.03), 0px 6px 6.5px 0px rgba(0, 0, 0, 0.05),
			0px 14px 13px 0px rgba(0, 0, 0, 0.06), 0px 28px 25px 0px rgba(0, 0, 0, 0.07),
			0px 46px 47px 0px rgba(0, 0, 0, 0.09), 0px 72px 80px 0px rgba(0, 0, 0, 0.12);

		/* Rings */

		--ring-brand: 0px 0px 0px 4px hsl(var(--brand-500) / 0.24);
		--ring-gray: 0px 0px 0px 4px hsl(var(--gray-400) / 0.14);
		--ring-gray-secondary: 0px 0px 0px 4px hsl(var(--gray-400) / 0.2);
		--ring-error: 0px 0px 0px 4px hsl(var(--error-500) / 0.24);
		--ring-accent: 0px 0px 0px 4px hsl(var(--orange-dark-200) / 0.64);

		/* Strikethrough */

		--diagonal-strikethrough: linear-gradient(
			to bottom right,
			transparent calc(50% - 1px),
			black,
			transparent calc(50% + 1px)
		);

		--strikethrough: linear-gradient(
				to bottom right,
				transparent calc(50% - 1px),
				rgba(0, 0, 0, 0.1),
				transparent calc(50% + 1px)
			),
			linear-gradient(
				to bottom left,
				transparent calc(50% - 1px),
				rgba(0, 0, 0, 0.1),
				transparent calc(50% + 1px)
			);

		/* Accelerator tokens */

		--chevron: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLWNoZXZyb24tZG93biI+PHBhdGggZD0ibTYgOSA2IDYgNi02Ii8+PC9zdmc+");
	}
}

@layer base {
	button,
	a,
	input,
	select,
	textarea {
		@apply rounded focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2;
	}

	body {
		@apply bg-white text-gray-900;
		font-feature-settings: "rlig" 1, "calt" 1;
	}

	h1,
	.prose h1 {
		@apply display-md lg:display-lg font-semibold;
	}
	h2,
	.prose h2 {
		@apply display-sm lg:display-md font-semibold;
	}
	h3,
	.prose h3 {
		@apply display-xs lg:display-sm font-semibold;
	}
	h4,
	.prose h4 {
		@apply lg:display-xs text-xl font-semibold;
	}
	h5,
	.prose h5 {
		@apply text-lg font-semibold lg:text-xl;
	}
	h6,
	.prose h6 {
		@apply text-md font-semibold lg:text-lg;
	}
	small,
	.prose small {
		@apply text-xs;
	}
	p,
	.prose p {
		@apply text-md;
	}

	.prose h1,
	.prose h2,
	.prose h3,
	.prose h4,
	.prose h5,
	.prose h6 {
		@apply mb-xl;
	}

	.prose a {
		@apply rounded-none border-b border-brand-500 font-medium text-brand no-underline transition-all hover:border-transparent hover:text-brand-600 hover:no-underline focus:border-brand-500 focus:bg-orange-dark-200 disabled:bg-transparent;
	}

	.prose blockquote {
		@apply m-0 flex flex-col border-l-2 border-brand-600 py-md pl-xl lg:pl-2xl;
	}

	.prose blockquote p {
		@apply m-0 max-w-3xl text-xl font-medium italic lg:text-2xl;
	}

	.prose blockquote p:first-child {
		@apply before:content-[open-quote] before:[mask-size:contain];
	}

	.prose blockquote p:nth-last-child(2) {
		@apply after:content-[close-quote] after:[mask-size:contain];
	}

	.prose blockquote p:last-child:not(:first-child) {
		@apply pt-3xl text-md font-normal not-italic text-gray-600 before:content-["—\00a0"] before:[mask-size:contain] lg:pt-4xl;
	}

	.hero-dark .prose blockquote p:last-child:not(:first-child),
	.section-header-dark .prose blockquote p:last-child:not(:first-child) {
		@apply pt-3xl text-md font-normal not-italic text-gray-100 before:content-["—\00a0"] before:[mask-size:contain] lg:pt-4xl;
	}

	.hero .prose p,
	.section-header .prose p {
		@apply text-lg lg:text-xl;
	}

	.hero .prose blockquote,
	.section-header .prose blockquote {
		@apply text-inherit;
	}

	.hero-dark .prose blockquote,
	.section-header-dark .prose blockquote {
		@apply border-brand-100;
	}

	hr {
		margin: 0;
	}
}

@layer utilities {
	/* Typography */

	.display-xs {
		@apply text-2xl;
	}

	.display-sm {
		@apply text-3xl;
	}

	.display-md {
		@apply text-4xl;
	}

	.display-lg {
		@apply text-5xl;
	}

	.display-xl {
		@apply text-6xl;
	}

	.display-2xl {
		@apply text-7xl;
	}

	/* Focus rings */

	.ring-brand {
		box-shadow: var(--ring-brand);
	}

	.ring-gray {
		box-shadow: var(--ring-gray);
	}

	.ring-gray-secondary {
		box-shadow: var(--ring-gray-secondary);
	}

	.ring-error {
		box-shadow: var(--ring-error);
	}

	.ring-accent {
		box-shadow: var(--ring-accent);
	}

	/* Focus rings with shadows */

	.ring-brand-shadow-xs {
		box-shadow: var(--ring-brand), var(--shadow-xs);
	}

	.ring-brand-shadow-sm {
		box-shadow: var(--ring-brand), var(--shadow-sm);
	}

	.ring-gray-shadow-xs {
		box-shadow: var(--ring-gray), var(--shadow-xs);
	}

	.ring-gray-shadow-sm {
		box-shadow: var(--ring-gray), var(--shadow-sm);
	}

	.ring-error-shadow-xs {
		box-shadow: var(--ring-error), var(--shadow-xs);
	}

	.ring-accent-level-2 {
		box-shadow: var(--shadow-level-2), var(--ring-accent);
	}

	/* Leading dots */

	.leading-dots {
		@apply border-b border-dashed border-gray-300 flex-grow relative -top-1.5;
	}

	/* Custom scrollbar */

	.custom-scrollbar {
		scrollbar-color: var(--gray-400) transparent;
	}

	.custom-scrollbar::-webkit-scrollbar-thumb {
		@apply bg-transparent;
	}

	.custom-scrollbar::-webkit-scrollbar {
		@apply w-2 bg-transparent;
	}

	.custom-scrollbar::-webkit-scrollbar-thumb {
		@apply rounded-lg bg-gray-400;
	}

	/* Cookiebot */

	#CookieDeclarationChangeConsentChange {
		@apply inline-flex items-center justify-center whitespace-nowrap font-semibold transition-colors disabled:pointer-events-none disabled:select-none disabled:bg-gray-50 disabled:text-gray-400 disabled:shadow-none focus:ring-accent-level-2 bg-brand text-white shadow-1 hover:bg-brand-600 hover:shadow-2 gap-sm px-xl py-2.5 rounded-l-none;
	}

	#CookieDeclarationChangeConsentWithdraw {
		@apply inline-flex items-center justify-center whitespace-nowrap font-semibold transition-colors disabled:pointer-events-none disabled:select-none disabled:bg-gray-50 disabled:text-gray-400 disabled:shadow-none focus:ring-accent-level-2 bg-gray-50 shadow-1 hover:border-gray-300 hover:bg-gray-100 hover:shadow-2 gap-sm px-xl py-2.5;
	}
}
